import React from 'react';
import styles from './userNavigation.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import UserListDropDown from '../userList/UserListDropDown';

export default function UserNavigation({ user }) {
    return (
        <>
            <div className={styles.userNavigation}>
                <HelpOutlineIcon color="disabled" />
                <NotificationsNoneIcon color="disabled" />
                <div>
                    <p className={styles.userName}>{user?.username}</p>
                    <p className={styles.userRoles}>{user?.roles?.join(',')}</p>
                </div>
                <UserListDropDown />
            </div>
        </>
    );
}
