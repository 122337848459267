import { createSelector } from '@reduxjs/toolkit';

export const cameraInfoSlice = (state) => state.cameraInfo;
export const selectAllLocationsLookup = createSelector([cameraInfoSlice], (slice) => {
    const dataSource = slice.allLocations;
    return { displayExpr: 'yardName', dataSource };
});

export const selectSelectedLocation = createSelector([cameraInfoSlice], (slice) => slice.selectedLocation);

export const selectAllCamerasLookup = createSelector([cameraInfoSlice], (slice) => {
    const dataSource = slice.allCamerasInfo;
    return { displayExpr: 'name', dataSource };
});
export const selectSelectedCameraRefresh = createSelector([cameraInfoSlice], (slice) => slice.selectedCameraRefresh);
export const selectCameraInfo = createSelector([cameraInfoSlice], (slice) => slice.allCamerasInfo);
export const selectCamerasStatus = createSelector([cameraInfoSlice], (slice) => slice.camerasStatus);
export const selectSelectedCamera = createSelector([cameraInfoSlice], (slice) => slice.selectedCamera);
export const selectLastRunStatus = createSelector([cameraInfoSlice], (slice) => slice.lastRunStatus);
export const selectLastRunTime = createSelector([cameraInfoSlice], (slice) => slice.lastRunTime);
export const selectRefreshLocationInProgress = createSelector([cameraInfoSlice], (slice) => slice.refreshLocationInProgress);
export const selectRefreshSingleCameraInProgress = createSelector([cameraInfoSlice], (slice) => slice.refreshSingleCameraInProgress);
