import styles from './Alerts.module.css';

import Alert from './Alert/Alert';

function Alerts({ alerts, containerClasses }) {
    const classes = `${styles['alert-container']}${
        containerClasses ? ` ${containerClasses}` : ''
    }`;

    return (
        <>
            {alerts && !!alerts.length && (
                <div className={classes}>
                    {alerts.map(({ text, color }) => {
                        return (
                            <Alert
                                key={`${text}${color}`}
                                text={text}
                                backgroundColor={color}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
}

export default Alerts;
