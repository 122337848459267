import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    fetchingEmployeesInProgress: false,
    fetchingEmployeesError: null,

    submittingFormInProgress: false,
    submittingFormError: null,

    formStatus: 'viewOnly',

    employeesList: [],
    selectedEmployee: null,
    beforeEditData: null,

    divisions: [],
    employeeRoles: [],

    employeePopupVisible: false,
};

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        fetchEmployeesStart(state) {
            state.fetchingEmployeesInProgress = true;
            state.fetchingEmployeesError = false;
        },
        fetchEmployeesSuccess(state, { payload: employeesList }) {
            state.fetchingEmployeesInProgress = false;
            state.employeesList = employeesList;
        },
        fetchEmployeesError(state, { payload: error }) {
            state.fetchingEmployeesInProgress = false;
            state.fetchingEmployeesError = error;
        },

        submitFormStart(state, { payload: type }) {
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
        },

        submitFormSuccess(state) {
            state.submittingFormInProgress = false;
        },

        submitFormFailed(state, { payload: error }) {
            state.submittingFormInProgress = false;
            state.submittingFormError = error;
        },

        setSelectedEmployee(state, { payload }) {
            state.selectedEmployee = payload ? state.employeesList.find((employee) => employee.id === payload) : null;
        },

        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },

        setBeforeEditData(state, { payload: employeeData = null }) {
            state.beforeEditData = employeeData;
        },

        resetEmployeeState(state) {
            state.fetchingEmployeesInProgress = false;
            state.fetchingEmployeesError = null;
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
            state.formStatus = 'viewOnly';
            state.employeesList = [];
            state.selectedEmployee = null;
            state.beforeEditData = null;
        },
        fetchDivisions() {},
        fetchDivisionSuccess(state, { payload: divisions }) {
            state.divisions = divisions;
        },
        fetchEmployeeRoles() {},
        populateEmployeeRoles(state, { payload: roles }) {
            state.employeeRoles = roles;
        },
        setPopupVisible(state, { payload: visible }) {
            state.employeePopupVisible = visible;
        },
    },
});

export const employeeActions = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;
