import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fetchingTableDataInProgress: {},
    fetchingTableDataError: {},

    fetchingTableConfigInProgress: false,
    fetchingTableConfigError: null,

    tableConfig: {},
    allTablesData: {},
};

const tableControlSlice = createSlice({
    name: 'locationTransactions',
    initialState,
    reducers: {
        fetchTableDataStart(state, { payload: { tableId } }) {
            state.fetchingTableDataInProgress[tableId] = true;
            state.fetchingTableDataError = remove(state.fetchingTableDataError, tableId);
        },
        fetchTableDataFailed(state, { payload: { error, tableId } }) {
            state.fetchingTableDataInProgress = remove(state.fetchingTableDataInProgress, tableId);
            state.fetchingTableDataError[tableId] = error;
        },
        fetchTableDataSuccess(state, { payload: { data, tableId, pageNum, append } }) {
            state.fetchingTableDataInProgress = remove(state.fetchingTableDataInProgress, tableId);
            const allTablesData = state.allTablesData;

            if (append) {
                allTablesData[tableId][pageNum] = { data };
            } else {
                allTablesData[tableId] = {
                    [pageNum]: { data },
                };
            }
        },

        fetchTableConfigStart(state) {
            state.fetchingTableConfigInProgress = true;
            state.fetchingTableConfigError = null;
        },
        fetchTableConfigSuccess(state, { payload: tableConfig }) {
            state.fetchingTableConfigInProgress = false;
            state.tableConfig = tableConfig;
        },
        fetchTableConfigFailed(state, { payload: error }) {
            state.fetchingTableConfigInProgress = false;
            state.fetchingTableConfigError = error;
        },

        setAllTablesData(state, { payload: newAllTablesData }) {
            state.allTablesData = newAllTablesData;
        },

        resetTableControlSlice(state) {
            state.allTablesData = {};
            state.tableConfig = {};
        },

        removeTable(state, { payload: tableId }) {
            const { [tableId]: toBeDeleted, ...rest } = state.allTablesData;
            state.allTablesData = rest;
        },
    },
});

function remove(obj, property) {
    const { [property]: _, ...rest } = obj;
    return rest;
}

export const tableControlReducer = tableControlSlice.reducer;
export const tableControlActions = tableControlSlice.actions;
