import { createSelector } from '@reduxjs/toolkit';

export const monitoringElementSelector = (state) => state.monitoringElementList;
export const selectedIsFormInEditingMode = createSelector([monitoringElementSelector], (slice) => slice.isFormInEditingMode);
export const selectedDropdownValues = createSelector([monitoringElementSelector], (slice) => slice.dropdownValues);
export const selectedFamilyIsCamera = createSelector([monitoringElementSelector], (slice) => slice.familyIsCamera);
export const activeMonitoringElementFamilyId = createSelector([monitoringElementSelector], (slice) => slice.activeMonitoringElementFamilyId);
export const removeModalState = createSelector([monitoringElementSelector], (slice) => slice.removeModalState);
export const selectedMonitoringElement = createSelector([monitoringElementSelector], (slice) => slice.monitoringElement);
export const monitoringElement = createSelector([monitoringElementSelector], (slice) => slice.monitoringElement);
export const isNVRSelected = createSelector([monitoringElementSelector], (slice) => slice.isNVRSelected);
