import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as formControlSelectors from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';
import { getFormFieldConfig } from 'utils/getFormFieldConfig';
import * as monitoringObjectSelector from 'store/monitoringObject/monitoringObjectSelectors';
import styles from './Form.module.css';
import InputField from 'components/UI/inputs/InputField';
import Select from 'components/UI/select/Select';

function Form({ formId, fields, classes, children, data, formStatus, dropdownValues, dropdownPersonValues }) {
    const dispatch = useDispatch();
    const selectFormByIdSelector = useMemo(() => formControlSelectors.selectFormById(formId), [formId]);
    const isEditing = useSelector(monitoringObjectSelector.selectIsEditing);
    const isOptionEqualToValueFn = (option, value) => option.id === value.id;

    useEffect(() => {
        dispatch(
            formControlActions.setFormValues({
                formId,
                newFormValues: data,
            })
        );
    }, [data, dispatch, formId]);

    const form = useSelector(selectFormByIdSelector);
    const { values, errors, touched } = form?.form || {};

    const formFields = fields.map((fieldConfig) => {
        const { fieldName } = fieldConfig;

        const options = {
            key: fieldName,
            value: values?.[fieldName] || '',
            error: errors?.[fieldName],
            touched: touched?.[fieldName],
            ...getFormFieldConfig({
                fieldConfig,
                formId,
                disabled: formStatus === 'viewOnly',
            }),
        };
        if (fieldConfig && fieldConfig?.lookup?.displayExpr === 'yardName') {
            const lookupObj = { ...fieldConfig?.lookup, dataSource: dropdownValues };

            return (
                <Select
                    containerClasses={styles['input-select']}
                    {...options}
                    lookup={lookupObj}
                    isOptionEqualToValueFn={isOptionEqualToValueFn}
                    {...(isEditing ? { disabled: true } : { disabled: false })}
                />
            );
        }

        if (fieldConfig && fieldConfig?.lookup?.displayExpr === 'objectType') {
            const lookupObj = { ...fieldConfig?.lookup, dataSource: dropdownPersonValues };

            return (
                <Select {...options} lookup={lookupObj} containerClasses={styles['input-select']} isOptionEqualToValueFn={isOptionEqualToValueFn} />
            );
        } else {
            return <InputField classes={styles['input']} {...options} />;
        }
    });
    return (
        <form id={formId} className={classes}>
            {formFields}
            {children}
        </form>
    );
}

export default Form;
