import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearLoader({
    containerStyles,
    progressStyles,
    progressOptions,
}) {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                ...containerStyles,
            }}
        >
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                }}
            >
                <LinearProgress
                    style={{ ...progressStyles }}
                    {...progressOptions}
                />
            </div>
        </div>
    );
}
