import React from 'react';
import Fallback from './fallback/Fallback';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    render() {
        if (this.state.error) {
            return <Fallback message={this.state.error.message} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
