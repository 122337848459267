import {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {mainConfig} from 'features/monitoringElements/table/tableConfig';
import {controlCentarActions} from 'store/controlCentar/controlCentarSlice';
import {tableControlActions} from 'store/tableControlSlice/tableControlSlice';
import * as controlCentarSelector from 'store/controlCentar/controlCentarSelectors';
import {peopleAuthorizedListActions} from 'store/peopleAuthorizedList/peopleAuthorizedListSlice';
import {
    controlCentarCamerasConfig,
    controlCentarLocationControl,
    controlCentarMainColumnsConfig
} from 'features/controlCentar/table/tableConfig';
import WaitingCircle from 'features/controlCentar/table/waitingCircle/waitingCircle';
import Table from 'features/controlCentar/table/Table';
import styles from './controlCentar.module.css';
import {useStompClient, useSubscription} from 'react-stomp-hooks';
import {snackbarActions} from 'store/snackbars/snackbarsSlice';
import LastaDialog from 'components/UI/dialogs/Dialog';

export const statusColors = {
        inactive: {color: '#919191', status: 'inactive'},
        active: {color: '#219653', status: 'active'},
        error: {color: '#EB5757', status: 'error'},
        partial: {color: '#F2C94C', status: 'partial'}
    }
;

function ControlCentar() {
    const dispatch = useDispatch();
    const tableData = useSelector(controlCentarSelector.locationValues);
    const cameraValues = useSelector(controlCentarSelector.cameraValues);
    const selectedLocationRow = useSelector(controlCentarSelector.selectedLocationRow);
    const modalState = useSelector(controlCentarSelector.modalState);
    const deployStatusMessages = useSelector(controlCentarSelector.deployStatusMessages);

    const stompClient = useStompClient();

    useLayoutEffect(() => {
        dispatch(peopleAuthorizedListActions.fetchAllLocations());
        dispatch(controlCentarActions.fetchTableValues());

        return () => {
            dispatch(tableControlActions.removeTable(mainConfig.tableId));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeDialogHandler = () => {
        dispatch(controlCentarActions.setModalState(false));
    };

    const spanStyleFunction = () => {
        let color;
        if (selectedLocationRow?.streamStatus === 'ERROR') {
            color = statusColors.error.color;
        } else if (selectedLocationRow?.streamStatus === 'ACTIVE') {
            color = statusColors.active.color;
        } else if (selectedLocationRow?.streamStatus === 'INACTIVE') {
            color = statusColors.inactive.color;
        } else if (selectedLocationRow?.streamStatus === 'PARTIAL') {
            color = statusColors.partial.color;
        }
        return { background: color };
    };

    const spanStyle = spanStyleFunction();

    const optionChangedHandler = (e) => {
        if (e.name === 'paging') dispatch(controlCentarActions.setSelectedLocationRow(null));
        if (e.name === 'focusedRowKey') {
            dispatch(controlCentarActions.setSelectedLocationRow(e.value));
        }
    };

    const optionChangedHandlerCameras = (e) => {
        if (e.name === 'paging') dispatch(controlCentarActions.setSelectedCameraRow(null));
        if (e.name === 'focusedRowKey') {
            dispatch(controlCentarActions.setSelectedCameraRow(e.value));
        }
    };

    const doubleClickHandler = () => {
        dispatch(controlCentarActions.fetchModalTableValues(selectedLocationRow?.id));
        dispatch(controlCentarActions.setModalState(true));
    };

    const renderStateCell = (data) => {
        let backgroundColor;
        let cellStatus;
        let statusStage;
        const stages = ['Alisa', 'Aiq', 'Aiq Database', 'Aiq Talk', 'MediaMtx'];

        stages.forEach((stage) => {
            if (data?.deploymentState?.stages || data.status) {
                switch (data?.deploymentState?.stages[stage]?.stageOutcome) {
                    case 'OK':
                        if (data.deploymentState.stages[stage].stageStatus === 'INACTIVE') {
                            statusStage = stage;
                            backgroundColor = statusColors.inactive.color;
                        } else if (data.deploymentState.stages[stage].stageStatus === 'ACTIVE') {
                            statusStage = stage;
                            backgroundColor = statusColors.active.color;
                        } else if (data.deploymentState.stages[stage].stageStatus === 'ERROR') {
                            statusStage = stage;
                            backgroundColor = statusColors.error.color;
                        } else if (data.deploymentState.stages[stage].stageStatus === 'PARTIAL') {
                            statusStage = stage;
                            backgroundColor = statusColors.partial.color;
                        } else if (data.deploymentState.stages[stage].stageStatus === 'WAITING') {
                            statusStage = stage;
                            cellStatus = <WaitingCircle />;
                        }
                        break;
                    case 'WAITING':
                        statusStage = stage;
                        cellStatus = <WaitingCircle />;
                        break;
                    case 'ERROR':
                        statusStage = stage;
                        backgroundColor = statusColors.error.color;
                        break;
                    default:
                }
                if (data.status === 'PARTIAL') {
                    backgroundColor = statusColors.partial.color;
                } else if (data.status === 'ERROR') {
                    backgroundColor = statusColors.error.color;
                } else if (data.status === 'WAITING') {
                    return <WaitingCircle />;
                } else if (data.status === 'INACTIVE') {
                    backgroundColor = statusColors.inactive.color;
                } else if (data.status === 'ACTIVE') {
                    backgroundColor = statusColors.active.color;
                }
            }
        });
        const spanStyle = {
            background: backgroundColor,
        };

        if (!cellStatus) {
            cellStatus = <span className={styles['status-circle']} style={spanStyle}></span>;
        }

        return (
            <div className={styles['status-messages-container']}>
                {cellStatus}
                <p>{data.id}</p>
                {data.name && <p>{data.name}</p>}
                {statusStage && <p> {statusStage}</p>}
                {data.errorMessage && <p>{data.errorMessage}</p>}
            </div>
        );
    };

    const handleControlStates = (sendData, destination) => {
        if (stompClient) {
            stompClient.publish({
                destination,
                body: sendData,
            });
            dispatch(
                snackbarActions.enqueueSnackbar({
                    message: 'IN PROGRESS...PLEASE WAIT',
                    options: {
                        variant: 'success',
                    },
                })
            );
        } else {
            dispatch(
                snackbarActions.enqueueSnackbar({
                    message: 'SOMETHING WENT WRONG',
                    options: {
                        variant: 'success',
                    },
                })
            );
        }
    };

    const returnLocationMessages = () => {
        return deployStatusMessages.map((el, index) => {
            let hasCameraDeployMessage = cameraValues.filter((camera) => camera.id === el.id);
            if (el.id === selectedLocationRow.id || hasCameraDeployMessage.length > 0) {
                let deployMessageName = el.id === selectedLocationRow.id ? selectedLocationRow.yardName : hasCameraDeployMessage[0]?.name;
                return (
                    <div key={`${el.id}${index}`} >
                        {renderStateCell({ ...el, name: deployMessageName })}
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <Subscribing />
            {!!tableData && (
                <Table
                    data={tableData}
                    columnsConfig={controlCentarMainColumnsConfig}
                    rowsPerPage={50}
                    classes={styles['main-table']}
                    optionChangedHandler={optionChangedHandler}
                    rowSelectionOnClick={true}
                    exportData={true}
                    hasSearch={true}
                    height={850}
                    onDoubleClick={doubleClickHandler}
                    showColumnLines={false}
                    loadPanel={true}
                />
            )}
            <LastaDialog
                open={modalState}
                onClose={closeDialogHandler}
                title={
                    <div className={styles['popup-title-container']}>
                        <span className={styles['popup-status-circle']} style={spanStyle}></span>
                        <p className={styles['close-popup-title']}>{selectedLocationRow?.yardName}</p>
                    </div>
                }
                classes={styles['lasta-dialog']}
            >
                <div className={styles['modal-content']}>
                    <p className={styles['table-heading']}>Location Status</p>
                    {!!tableData && (
                        <Table
                            data={[selectedLocationRow]}
                            columnsConfig={controlCentarLocationControl}
                            rowsPerPage={50}
                            optionChangedHandler={optionChangedHandler}
                            showColumnLines={false}
                            handleControlStates={handleControlStates}
                        />
                    )}
                    <p className={styles['table-heading']}>Camera Status</p>
                    {!!cameraValues && (
                        <Table
                            classes={styles['main-table-modal']}
                            data={cameraValues}
                            columnsConfig={controlCentarCamerasConfig}
                            showColumnLines={false}
                            optionChangedHandler={optionChangedHandlerCameras}
                            rowSelectionOnClick={true}
                            handleControlStates={handleControlStates}
                        />
                    )}
                    <div className={styles['table-heading']}>
                        <p className={styles['status-log-info']}>Status Messages </p>
                        {Object.keys(statusColors).map((key) => (
                            <p className={styles['status-log-info']}>
                                <span className={styles['status-circle']} style={{ background: statusColors[key].color }}></span>
                                <span className={styles['status-circle-text']}>{statusColors[key].status}</span>
                            </p>
                        ))}
                        <p className={styles['status-log-info']}>
                            <WaitingCircle />
                            <span className={styles['status-circle-text']}>waiting</span>
                        </p>
                    </div>
                    <div className={styles['status-list']}>{returnLocationMessages()}</div>
                </div>
            </LastaDialog>
        </>
    );
}

export default ControlCentar;

export function Subscribing() {
    const dispatch = useDispatch();

    useSubscription('/topic/location-progress', (message) => {
        dispatch(controlCentarActions.setDeploymentLocationStages(JSON.parse(message.body)));
    });
    useSubscription('/topic/camera-progress', (message) => {
        console.log(message.body);

        dispatch(controlCentarActions.setDeploymentCameraStages(JSON.parse(message.body)));
    });
    useSubscription('/topic/location-status', (message) => {
        dispatch(controlCentarActions.setStatusLocationStages(JSON.parse(message.body)));
    });
    useSubscription('/topic/camera-status', (message) => {
        // console.log(message.body);
        dispatch(controlCentarActions.setStatusCameraStages(JSON.parse(message.body)));
    });
}
