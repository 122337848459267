import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import styles from './Navigation.module.css';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import { routes } from './navigationRoutes';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import DrawerListItem, { FoldableDrawerListItem } from './DrawerListItem/DrawerListItem';
import { SSO_REDIRECT_TO_ADDRESS } from 'config';
import UserNavigation from 'components/lorriniCompnents/navigation/userNavigation/UserNavigation';

function NavigationDrawer({ user }) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawerOpenHandler = () => setDrawerOpen((state) => !state);

    const homeButtonClickHandler = () => window.location.assign(SSO_REDIRECT_TO_ADDRESS);

    const availableRoutes = getAvailableRoutes(routes, user, toggleDrawerOpenHandler);

    return (
        <div className={styles.navigation}>
            <IconButton onClick={toggleDrawerOpenHandler}>
                <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawerOpenHandler}>
                <List sx={{ paddingTop: 'var(--header__height--main)' }}>
                    <DrawerListItem onClick={homeButtonClickHandler} text="Birdseye Home" Icon={HomeIcon} />
                    <Divider />
                    {availableRoutes}
                    <Divider />
                </List>
            </Drawer>
            <UserNavigation user={user} />
        </div>
    );
}

export default NavigationDrawer;

function checkIsAllowed(user, allowedRoles) {
    if (!allowedRoles) return true;
    let allowedAccess = false;
    if (user) {
        allowedAccess = allowedRoles.reduce((allowedAccess, allowedRole) => {
            !allowedAccess && user.roles.includes(allowedRole) && (allowedAccess = true);
            return allowedAccess;
        }, false);
    }

    return allowedAccess;
}

export function getAvailableRoutes(routes, user, onClick) {
    return routes.reduce((availableRoutes, { text, path, allowedRoles, items }) => {
        if (!checkIsAllowed(user, allowedRoles)) return availableRoutes;

        if (items) {
            const routeItem = <FoldableDrawerListItem key={text} text={text} childListItems={items} user={user} onClick={onClick} />;
            availableRoutes.push(routeItem);
            return availableRoutes;
        }

        const routeItem = <DrawerListItem key={text} listItemComponent={Link} listItemAttributes={{ to: path }} onClick={onClick} text={text} />;
        availableRoutes.push(routeItem);
        return availableRoutes;
    }, []);
}
