import styles from './Alert.module.css';
import { useRef } from 'react';
import { useEffect } from 'react';

function Alert({ text, backgroundColor }) {
    const alertRef = useRef();
    useEffect(() => {
        const { current: alertElement } = alertRef;
        if (backgroundColor) {
            alertElement.style.backgroundColor = backgroundColor;
        }
        return () => {
            alertElement.removeAttribute('style');
        };
    }, [backgroundColor]);
    return (
        <p
            className={`${styles['alert']}${
                !text ? ` ${styles['alert--rounded']}` : ''
            }`}
            ref={alertRef}
        >
            {text}
        </p>
    );
}

export default Alert;
