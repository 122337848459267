export const controlCentarMainColumnsConfig = [
    {
        columns: [
            {
                caption: 'Status',
                dataField: 'streamStatus',
                dataType: 'boolean',
                editable: false,
            },
            {
                caption: 'Id',
                dataField: 'id',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Location Name',
                dataField: 'yardName',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Alisa',
                dataField: 'deploymentState.stages.Alisa.stageStatus',
                dataType: 'boolean',
                editable: false,
            },
            {
                caption: 'Alisa',
                dataField: 'deploymentState.stages.Alisa.stageOutcome',
                dataType: 'boolean',
                editable: false,
                visible: false,
            },
        ],
    },
];

export const controlCentarLocationControl = [
    {
        columns: [
            {
                caption: 'Status',
                dataField: 'streamStatus',
                dataType: 'boolean',
                editable: false,
                alignment: 'left',
                width: 110,
            },
            {
                caption: 'Id',
                dataField: 'id',
                dataType: 'string',
                editable: false,
                width: 150,
            },
            {
                caption: 'Location Name',
                dataField: 'yardName',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Alisa',
                dataField: 'deploymentState.stages.Alisa.stageStatus',
                dataType: 'boolean',
                editable: false,
            },
            {
                caption: 'Alisa',
                dataField: 'deploymentState.stages.Alisa.stageOutcome',
                dataType: 'boolean',
                editable: false,
                visible: false,
            },
            {
                caption: 'Controls',
                dataField: 'controlsLocationButton',
                dataType: 'string',
                editable: false,
                width: '170',
            },
        ],
    },
];

export const controlCentarCamerasConfig = [
    {
        columns: [
            {
                caption: 'Status',
                dataField: 'status',
                dataType: 'boolean',
                editable: false,
                alignment: 'left',
                width: 110,
            },
            {
                caption: 'Id',
                dataField: 'id',
                dataType: 'string',
                editable: false,
                width: 150,
            },
            {
                caption: 'Camera Name',
                dataField: 'name',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Monitoring Object',
                dataField: 'cameraName',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Aiq Talk',
                dataField: 'deploymentState.stages.Aiq Talk.stageOutcome',
                dataType: 'boolean',
                editable: false,
                visible: false,
            },
            {
                caption: 'Aiq Database',
                dataField: 'deploymentState.stages.Aiq Database.stageOutcome',
                dataType: 'boolean',
                editable: false,
                visible: false,
            },
            {
                caption: 'MediaMtx',
                dataField: 'deploymentState.stages.MediaMtx.stageOutcome',
                dataType: 'boolean',
                editable: false,
                visible: false,
            },
            {
                caption: 'Kurento',
                dataField: 'deploymentState.stages.Aiq.stageOutcome',
                dataType: 'boolean',
                editable: false,
                visible: false,
            },
            {
                caption: 'Aiq Talk',
                dataField: 'deploymentState.stages.Aiq Talk.stageStatus',
                dataType: 'boolean',
                editable: false,
                width: 100,
            },
            {
                caption: 'Aiq Database',
                dataField: 'deploymentState.stages.Aiq Database.stageStatus',
                dataType: 'boolean',
                editable: false,
                width: 100,
            },
            {
                caption: 'MediaMtx',
                dataField: 'deploymentState.stages.MediaMtx.stageStatus',
                dataType: 'boolean',
                editable: false,
                width: 100,
            },
            {
                caption: 'Kurento',
                dataField: 'deploymentState.stages.Aiq.stageStatus',
                dataType: 'boolean',
                editable: false,
                width: 100,
            },
            {
                caption: 'Controls',
                dataField: 'controlsCameraButton',
                dataType: 'string',
                editable: false,
                width: '170',
            },
        ],
    },
];
