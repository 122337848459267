import styles from './Input.module.css';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { useRef } from 'react';

function Input({
    id,
    name,
    disabled,
    value,
    type,
    placeholder,
    autoFocus,
    defaultValue,
    onChange = () => {},
    onBlur = () => {},
    inputStyles,
    inputProps,
    setFocused,
    automation,
    onFocus,
    focused,
    dropdownArrow,
}) {
    const inputRef = useRef();
    const handleFocus = (e) => {
        setFocused(true);
        onFocus && onFocus(true);
    };

    const handleBlur = (e) => {
        setFocused(false);
        onFocus && onFocus(false);

        if (value === '' && automation?.sourceValue) {
            e.target.value = automation.sourceValue;
            onChange(e);
        }

        onBlur(e);
    };

    return (
        <div className={styles['input-wrapper']}>
            <input
                id={id}
                name={name}
                disabled={disabled}
                className={inputStyles}
                value={value}
                type={type}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={placeholder}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                autoComplete="off"
                spellCheck="false"
                {...inputProps}
                ref={inputRef}
            />
            {!focused && dropdownArrow && (
                <ArrowDropDownRoundedIcon
                    sx={{
                        position: 'absolute',
                        right: '-0.2rem',
                        bottom: '0',
                        color: 'rgba(0, 0, 0, 0.5)',
                        cursor: 'pointer',
                    }}
                    onClick={() => inputRef.current.focus()}
                />
            )}
        </div>
    );
}

export default Input;
