import styles from './Fallback.module.css';

function Fallback({ message }) {
    return (
        <div className={styles['error-container']}>
            <p className={styles['header']}>Ups... something went wrong...</p>
            <div>
                <p className={styles['header--message']}>Message:</p>
                <p className={styles['message']}>{message}</p>
            </div>
        </div>
    );
}

export default Fallback;
