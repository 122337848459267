import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

const NotificationDismissButton = function ({ notificationId }) {
    const { closeSnackbar } = useSnackbar();
    return (
        <Button color="warning" onClick={() => closeSnackbar(notificationId)}>
            Dismiss
        </Button>
    );
};

export const action = (key) => <NotificationDismissButton notificationId={key} />;

export default NotificationDismissButton;
