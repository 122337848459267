import { createSelector } from '@reduxjs/toolkit';

export const controlCentarSelector = (state) => state.controlCentarList;

export const selectedMonitoringElement = createSelector([controlCentarSelector], (slice) => slice.selectedMonitoringElement);
export const selectedTableValues = createSelector([controlCentarSelector], (slice) => slice.tableValues);
export const selectedLocationRow = createSelector([controlCentarSelector], (slice) => slice.selectedLocationRow);
export const selectedCameraRow = createSelector([controlCentarSelector], (slice) => slice.selectedCameraRow);
export const modalState = createSelector([controlCentarSelector], (slice) => slice.modalState);
export const cameraValues = createSelector([controlCentarSelector], (slice) => slice.cameraValues);
export const locationValues = createSelector([controlCentarSelector], (slice) => slice.locationValues);
export const deployStatusMessages = createSelector([controlCentarSelector], (slice) => slice.deployStatusMessages);
export const stateStatusMessages = createSelector([controlCentarSelector], (slice) => slice.stateStatusMessages);
export const buttonState = createSelector([controlCentarSelector], (slice) => slice.buttonState);
