import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    submittingFormInProgress: false,
    submittingFormError: null,

    masterList: [],
    selectedMasterList: null,

    formStatus: 'viewOnly',
    beforeEditData: null,

    removePopupVisible: false,
    addPopupVisible: false,

    authorizedItems: [],
    selectedAuthorizedItem: null,

    authorizedItemListAlerts: [],
    selectedAuthorizedItemAlert: null,

    authTypeFormStatus: 'viewOnly',

    beforeEditDataAuthType: null,

    authTypePopupVisible: false,
    removeAuthTypePopupVisible: false,
    addAuthTypePopupVisible: false,
};

const divisionListSlice = createSlice({
    name: 'divisionList',
    initialState,
    reducers: {
        fetchMasterListStart() {},
        fetchMasterListSuccess(state, { payload: companyList }) {
            state.masterList = companyList;
        },
        setSelectedMasterListItem(state, { payload }) {
            state.selectedMasterList = payload ? state.masterList.find((comapny) => comapny.id === payload) : null;
        },
        submitFormStart(state) {
            state.submittingFormError = null;
        },
        submitFormFailed(state, { payload: error }) {
            state.submittingFormError = error;
        },
        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },
        setBeforeEditData(state, { payload: personData = null }) {
            state.beforeEditData = personData;
        },
        resetState(state) {
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
            state.formStatus = 'viewOnly';
            state.masterList = [];
            state.selectedMasterList = null;
            state.beforeEditData = null;
            state.selectedMasterList = null;
            state.authTypeList = [];
        },
        setRemovePopupVisible(state, { payload }) {
            state.removePopupVisible = payload;
        },
        setAddPopupVisible(state, { payload }) {
            state.addPopupVisible = payload;
        },
        addItemToMasterList() {},
        fetchDesksSuccess(state, { payload }) {
            state.authorizedItems = payload;
            state.selectedAuthorizedItemAlert = payload;
        },
        fetchAuthorizedItemAuthTypes(state, { payload: authTypeList }) {
            state.authorizedItemListAlerts = authTypeList;
        },
        resetItemsAuthorizations(state) {
            state.authorizedItemListAlerts = [];
        },
        setSelectedAuthorizedItem(state, { payload }) {
            state.selectedAuthorizedItem = payload ? state.masterList.find((person) => person.id === payload) : null;
        },
        setSelectedAlert(state, { payload }) {
            state.selectedAuthorizedItemAlert = payload ? state.authorizedItemListAlerts.find((alert) => alert.id === payload) : null;
        },
        setBeforeEditDataAuthType(state, { payload: personAlert = null }) {
            state.beforeEditDataAuthType = personAlert;
        },
        setAuthTypePopupVisible(state, { payload: visible }) {
            state.authTypePopupVisible = visible;
        },
        setRemoveAuthTypePopupVisible(state, { payload }) {
            state.removeAuthTypePopupVisible = payload;
        },
        setAddAuthTypePopupVisible(state, { payload }) {
            state.addAuthTypePopupVisible = payload;
        },
        addItemToAuthorizedList() {},
        setAuthTypeList(state, { payload: authTypes }) {
            state.authTypeList = authTypes;
        },
        fetchAuthTypeList() {},
        deleteAuthorizedItem() {},
        deleteAuthType() {},
    },
});

export const divisionListActions = divisionListSlice.actions;
export const divisionListReducer = divisionListSlice.reducer;
