import { call, put, select } from 'redux-saga/effects';
import { selectFormById } from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';
import store from 'store/store';
import notificationsHandler from 'utils/notificationsHandler';
import { axiosLasta } from 'config';

export function* FormSaga({ payload: type }) {
    try {
        const formId = type.formId;
        const {
            form: { values, errors },
        } = yield select(selectFormById(formId));
        let isFormValid = true;

        Object.keys(errors).forEach((fieldName) => {
            if (errors[fieldName]) {
                isFormValid = false;
                store.dispatch(formControlActions.setFieldTouched({ formId, fieldName }));
            }
        });

        if (!isFormValid) {
            yield notificationsHandler({
                title: 'Please fill required fields',
                variant: 'warning',
            });
            return;
        }

        let message;

        if (type.formStatus === 'add') {
            const editedValues = { ...values, location: type.locationid };

            for (const property in editedValues) {
                if (editedValues[property] === '') editedValues[property] = null;
            }

            const response = yield axiosLasta.post(type.postUrl, editedValues);

            ({ message } = response.data);
        }
        if (type.formStatus === 'edit') {
            const beforeEditData = type.beforeEditData;

            const hasChanged = Object.keys(values).reduce((hasChanged, fieldName) => {
                let setHasChanged = false;

                if (typeof values[fieldName] === 'object' && values[fieldName]?.name !== beforeEditData[fieldName]?.name) {
                    setHasChanged = true;
                }

                if (
                    (typeof values[fieldName] === 'string' || typeof values[fieldName] === 'number' || values[fieldName] === null) &&
                    values[fieldName] !== beforeEditData[fieldName]
                ) {
                    setHasChanged = true;
                }

                return !hasChanged ? setHasChanged : true;
            }, false);

            if (!hasChanged) {
                yield call(notificationsHandler, {
                    title: 'No changes detected',
                    variant: 'info',
                });
                return;
            }

            const editedValues = { ...values, id: type.id };
            delete editedValues.location;
            const response = yield axiosLasta.put(type.putUrl, editedValues);
            ({ message } = response.data);
        }

        if (message.includes('already exist')) {
            yield put(
                formControlActions.setFieldError({
                    formId,
                    fieldName: type.fields.name.fieldName,
                    error: `${type.formFor} already exists`,
                })
            );
            return;
        }

        if (message.includes('SUCCESS')) {
            yield put(type.actions.fetchTableValues(type.locationid));
            yield put(type.actions.setModalState(false));

            yield notificationsHandler({
                title: `SUCCESSFULLY UPDATED`,
                variant: 'success',
            });
        }
    } catch (err) {
        yield put(type.actions.submitFormFailed(JSON.stringify(err.message)));
    }
}
