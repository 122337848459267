import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { monitoringObjectActions } from 'store/monitoringObject/monitoringObjectSlice';
import * as monitoringObjectSelector from 'store/monitoringObject/monitoringObjectSelectors';
import { monitoringObjectConfig } from 'features/monitoringObject/tableConfig';
import Header from 'features/monitoringObject/header/Header';
import FormActions from 'features/employee/form/formActions/FormActions';
import LastaDialog from 'components/UI/dialogs/Dialog';
import { formControlActions } from 'store/formControl/formControlSlice';
import { companyAuthorizationListActions } from 'store/companyAuthorizedList/companyAuthorizationListSlice';
import Form from 'features/monitoringObject/form/Form';
import { monitoringObject } from 'features/monitoringObject/config';
import { selectSelectedLocation } from 'store/companyAuthorizedList/companyAuthorizationListSelectors';
import Table from 'components/UI/table/Table';
import styles from './MonitoringObject.module.css';

const formId = monitoringObject.locationListForm.id;
const fields = monitoringObject.locationListForm.fields;

function MonitoringObject() {
    const dispatch = useDispatch();
    const tableData = useSelector(monitoringObjectSelector.locationValues);
    const selectedSelectedRow = useSelector(monitoringObjectSelector.selectedSelectedRow);
    const modalState = useSelector(monitoringObjectSelector.modalState);
    const locations = useSelector(monitoringObjectSelector.locations);
    const monitoringObjectType = useSelector(monitoringObjectSelector.monitoringObjectType);
    const beforeEditData = useSelector(monitoringObjectSelector.selectBeforeEditData);
    const formStatus = useSelector(monitoringObjectSelector.selectFormStatus);
    const selectedLocation = useSelector(selectSelectedLocation);
    const isEditing = useSelector(monitoringObjectSelector.selectIsEditing);

    useLayoutEffect(() => {
        dispatch(formControlActions.registerNewForm(formId));
        dispatch(companyAuthorizationListActions.fetchAllLocations());
        dispatch(monitoringObjectActions.fetchLocation());

        return () => {
            dispatch(formControlActions.removeForm(formId));
            dispatch(companyAuthorizationListActions.resetAuthorizedState());
            dispatch(monitoringObjectActions.resetState());
        };
    }, [dispatch]);

    const optionChangedHandler = (e) => {
        if (e.name === 'paging') dispatch(monitoringObjectActions.setSelectedRow(null));
        if (e.name === 'focusedRowKey') {
            dispatch(monitoringObjectActions.setSelectedRow(e.value));
            dispatch(monitoringObjectActions.setBeforeEditData(e.value));
        }
    };

    const closeDialogHandler = () => {
        dispatch(monitoringObjectActions.setModalState(false));
    };

    const doubleClickHandler = () => {
        dispatch(monitoringObjectActions.setFormStatus('edit'));
        dispatch(monitoringObjectActions.setIsEditing(true));
        dispatch(monitoringObjectActions.setModalState(true));
        dispatch(monitoringObjectActions.fetchSingleMonitoringObject(selectedSelectedRow?.id));
    };

    const addHandler = () => {
        dispatch(monitoringObjectActions.setModalState(true));
        dispatch(monitoringObjectActions.setSelectedRow(null));
        dispatch(monitoringObjectActions.setFormStatus('add'));
        dispatch(monitoringObjectActions.setIsEditing(false));
    };

    const updateMonitoringObject = (e) => {
        e.preventDefault();
        e.target.focus();
        const payload = {
            formId,
            fields,
            formStatus,
            beforeEditData,
            actions: monitoringObjectActions,
            postUrl: '/monitoring/object',
            putUrl: '/monitoring/object',
            formFor: 'Monitoring object',
            id: selectedSelectedRow?.id,
            locationid: selectedLocation,
        };
        dispatch(monitoringObjectActions.submitFormStart(payload));
    };

    return (
        <div className={styles['main-container']}>
            <Header addHandler={addHandler} />
            {!!tableData && (
                <Table
                    data={tableData}
                    columnsConfig={monitoringObjectConfig}
                    rowsPerPage={50}
                    optionChangedHandler={optionChangedHandler}
                    onDoubleClick={doubleClickHandler}
                    classes={styles['main-table']}
                    hasSearch={true}
                    height={850}
                    exportData={true}
                    showColumnLines={false}
                    loadPanel={true}
                    rowSelectionOnClick={true}
                />
            )}
            <LastaDialog title={`${isEditing ? 'Edit' : 'Create'} monitoring object`} open={modalState} onClose={closeDialogHandler}>
                <div className={styles['modal-content']}>
                    <Form
                        formId={formId}
                        fields={fields}
                        classes={styles['monitoring-form']}
                        data={selectedSelectedRow}
                        formStatus="add"
                        dropdownValues={locations}
                        dropdownPersonValues={monitoringObjectType}
                    >
                        <FormActions submitHandler={updateMonitoringObject} discardHandler={closeDialogHandler} />
                    </Form>
                </div>
            </LastaDialog>
        </div>
    );
}

export default MonitoringObject;
