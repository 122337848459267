import * as cameraInfoSelectors from 'store/cameraInfo/cameraInfoSelectors';
import { cameraInfoActions } from 'store/cameraInfo/cameraInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { tableColumnsConfig } from './tableConfig';
import Table from 'components/UI/table/Table';
import { useSubscription } from 'react-stomp-hooks';
import styles from './MonitoringElementsTable.module.css';

function CameraElementsTable() {
    const dispatch = useDispatch();
    const allCamerasInfo = useSelector(cameraInfoSelectors.selectCameraInfo);
    const selectedCamera = useSelector(cameraInfoSelectors.selectSelectedCamera);
    const refreshSingleCameraInProgress = useSelector(cameraInfoSelectors.selectRefreshSingleCameraInProgress);

    const optionChangedHandler = (e) => {
        if (e.name === 'focusedRowKey') {
            dispatch(cameraInfoActions.setSelectedCamera(e.value));
            !refreshSingleCameraInProgress && dispatch(cameraInfoActions.setSelectedCameraRefresh(null));
        }
    };

    const handleDoubleClick = () => {
        dispatch(cameraInfoActions.refreshCameraInfo(selectedCamera));
    };

    return (
        <div>
            <Subscribing />
            {!!allCamerasInfo && (
                <Table
                    pageFlag="CameraInfo"
                    onDoubleClick={handleDoubleClick}
                    data={allCamerasInfo}
                    columnsConfig={tableColumnsConfig}
                    rowsPerPage={50}
                    classes={styles['table']}
                    disabled={false}
                    optionChangedHandler={optionChangedHandler}
                    hasSearch={true}
                    cameraThrone={true}
                    height={800}
                    exportData={true}
                    showColumnLines={true}
                    loadPanel={true}
                    showRowLines={true}
                    rowSelectionOnClick={true}
                />
            )}
        </div>
    );
}

export default CameraElementsTable;

export function Subscribing() {
    const previousCameraStatus = useSelector(cameraInfoSelectors.selectCamerasStatus);
    const dispatch = useDispatch();
    //Subscribe to /topic/test, and use handler for all received messages
    //Note that all subscriptions made through the library are automatically removed when their owning component gets unmounted.
    //If the STOMP connection itself is lost they are however restored on reconnect.
    //You can also supply an array as the first parameter, which will subscribe to all destinations in the array

    useSubscription('/topic/camera-validation', (message) => {
        const cameraStatus = JSON.parse(message.body);
        const newId = cameraStatus.cameraId;
        const newObject = {};
        newObject[newId] = { ...cameraStatus.validation };
        dispatch(cameraInfoActions.refreshCameraStatus({ ...previousCameraStatus, ...newObject }));
    });
}
