import { Button } from '@mui/material';
import styles from './FormActions.module.css';

function FormActions({ submitHandler, discardHandler }) {
    return (
        <>
            <div className={`${styles['form--actions']} ${styles['actions']}`}>
                <Button onClick={discardHandler} color="error" variant="outlined" size="medium">
                    Cancel
                </Button>
                <Button color="success" variant="contained" size="medium" type="submit" onClick={submitHandler}>
                    Save changes
                </Button>
            </div>
        </>
    );
}

export default FormActions;
