import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLocations: [],
    selectedLocation: null,
    allCamerasInfo: [],
    selectedCameraRefresh: null,
    selectedCamera: null,
    lastRunTime: null,
    lastRunStatus: null,
    refreshLocationInProgress: false,
    refreshSingleCameraInProgress: false,
    camerasStatus: {},
};

const cameraInfoSlice = createSlice({
    name: 'cameraInfo',
    initialState,
    reducers: {
        fetchAllLocations() {},
        setAllLocations(state, { payload: allLocations }) {
            state.allLocations = allLocations;
        },
        setSelectedLocation(state, { payload: selectedLocation }) {
            state.selectedLocation = selectedLocation;
        },
        fetchAllCameraInfo() {},
        fetchCameraInfoPerLocation() {},
        fetchSingleCameraSuccess(state, { payload: refreshedData }) {
            state.allCamerasInfo = state.allCamerasInfo.map((camera) => {
                if (camera.id === refreshedData.id) {
                    return refreshedData;
                } else {
                    return camera;
                }
            });
        },
        fetchCameraInfo() {},
        fetchCameraInfoSuccess(state, { payload: allCameras }) {
            state.allCamerasInfo = allCameras;
        },
        setSelectedCamera(state, { payload: selectedCameraId }) {
            state.selectedCamera = selectedCameraId ? state.allCamerasInfo.find((camera) => camera.id === selectedCameraId) : null;
        },
        setLastRunTime(state, { payload: info }) {
            state.lastRunTime = `${info.slice(0, 10)} ${info.slice(11, 16)}`;
        },
        setLastRunStatus(state, { payload: info }) {
            state.lastRunStatus = info;
        },
        setSelectedCameraRefresh(state, { payload: selectedCameraRefresh }) {
            state.selectedCameraRefresh = selectedCameraRefresh;
        },
        refreshCameraInfo() {},
        refreshCameraInfoPerLocation() {},
        setRefreshLocationInProgress(state, { payload }) {
            state.refreshLocationInProgress = payload;
        },
        setRefreshSingleCameraInProgress(state, { payload }) {
            state.refreshSingleCameraInProgress = payload;
        },
        refreshCameraStatus(state, { payload: cameraStatus }) {
            state.camerasStatus = cameraStatus;

            for (const key in cameraStatus) {
                state.allCamerasInfo = state.allCamerasInfo.map((camera) => {
                    if (camera.id.toString() === key) {
                        return { ...camera, ...cameraStatus[key] };
                    } else {
                        return camera;
                    }
                });
            }
        },
        resetState(state) {
            state.selectedCameraRefresh = null;
            state.selectedCamera = null;
            state.lastRunTime = null;
            state.lastRunStatus = null;
            state.refreshLocationInProgress = false;
            state.refreshSingleCameraInProgress = false;
        },
    },
});

export const cameraInfoActions = cameraInfoSlice.actions;
export const cameraInfoReducer = cameraInfoSlice.reducer;
