import { all, call, put, takeLatest } from 'redux-saga/effects';

import notificationsHandler from 'utils/notificationsHandler';
import { employeeActions } from './empoloyeeSlice';
import { FormSaga } from 'store/formControl/formPeopleEmployeeSaga';
import { axiosLasta } from 'config';

export function* onFetchingEmployeesStart() {
    yield takeLatest(employeeActions.fetchEmployeesStart.type, fetchEmployees);
}

export function* fetchEmployees({ payload: employeeId }) {
    try {
        const response = yield axiosLasta(`/employee/all`);
        const { data } = response;

        const employeesList = data.map((employee) => {
            employee.terminationDate === null && (employee.terminationDate = '');
            employee.divisionName = employee.division?.name;
            employee.division = employee.division?.id;
            return employee;
        });

        yield put(employeeActions.fetchEmployeesSuccess(employeesList));

        if (employeeId) yield put(employeeActions.setSelectedEmployee(employeeId));
    } catch (err) {
        yield put(employeeActions.fetchEmployeesError(err));
        yield notificationsHandler({ title: 'Error fetching employees', err });
    }
}

export function* fetchDivisions() {
    try {
        const response = yield axiosLasta(`station/division/all`);

        yield put(employeeActions.fetchDivisionSuccess(response.data));
    } catch (err) {
        yield notificationsHandler({ title: 'Error fetching divisions', err });
    }
}

export function* fetchemployeeRoles() {
    try {
        const response = yield axiosLasta(`employee/roles`);
        const obj = [];

        for (const key of response.data) {
            let object = {};
            object['employeeRole'] = key;
            object['id'] = key;
            obj.push(object);
            obj.sort(function (a, b) {
                var textA = a?.employeeRole?.toUpperCase();
                var textB = b?.employeeRole?.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        }

        yield put(employeeActions.populateEmployeeRoles(obj));
    } catch (err) {
        yield notificationsHandler({ title: 'Error fetching roles', err });
    }
}

export function* onSubmitFormStart() {
    yield takeLatest(employeeActions.submitFormStart, FormSaga);
}

export function* onFetchDivisions() {
    yield takeLatest(employeeActions.fetchDivisions, fetchDivisions);
}

export function* onfetchemployeeRoles() {
    yield takeLatest(employeeActions.fetchEmployeeRoles, fetchemployeeRoles);
}

export function* employeeSaga() {
    yield all([call(onFetchingEmployeesStart), call(onSubmitFormStart), call(onFetchDivisions), call(onfetchemployeeRoles)]);
}
