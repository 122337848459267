import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Redirect.module.css';
import { SSO_REDIRECT_TO_ADDRESS } from 'config';

function Redirect({ user }) {
    const navigate = useNavigate();

    useEffect(() => {
        user && navigate('/');
        !user && window.location.replace(SSO_REDIRECT_TO_ADDRESS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return <p className={styles['redirect-text']}>Redirecting to Birdseye SSO...</p>;
}

export default Redirect;
