import * as fieldControlers from 'store/formControl/fieldControllers';

export const getFormFieldConfig = function ({ fieldConfig, formId, helperText = 'This field is required', disabled = false }) {
    const { required, label } = fieldConfig;

    return {
        ...fieldConfig,
        ...fieldControlers,
        label: required ? `${label}*` : label,
        formId,
        helperText,
        disabled,
    };
};

export const getLookup = (lookup, dropdownValues, fieldName) => {
    return {
        ...lookup,
        dataSource:
            !lookup?.dataSource && dropdownValues[fieldName]?.dataSource
                ? dropdownValues[fieldName]?.dataSource
                : lookup?.dataSource
                ? lookup?.dataSource
                : [],
    };
};
