import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    isUserDataRequested: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, { payload: user }) {
            state.user = user;
        },

        setIsUserDataRequested(state, { payload }) {
            state.isUserDataRequested = payload;
        },

        //placeholders
        handleUnauthorized() {},
        logoutStart() {},
        getUserData() {},
    },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
