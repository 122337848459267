import { axiosLasta } from 'config';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import notificationsHandler from 'utils/notificationsHandler';
import { FormOrganizationSaga } from 'store/formControl/formOrgnaizationSaga';
import { organizationListActions } from './organizationListSlice';

export function* fetchMasterList() {
    try {
        const response = yield axiosLasta(`/organization/all`);
        const { data } = response;
        yield put(organizationListActions.fetchMasterListSuccess(data));
    } catch (err) {
        yield notificationsHandler({ title: 'Error fetching all organizations' });
    }
}

export function* onFetchingMasterListStart() {
    yield takeEvery(organizationListActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(organizationListActions.submitFormStart, FormOrganizationSaga);
}

export function* onAddItemToMasterList() {
    yield takeLatest(organizationListActions.addItemToMasterList.type, FormOrganizationSaga);
}

export function* organizationListSaga() {
    yield all([call(onSubmitFormStart), call(onAddItemToMasterList), call(onFetchingMasterListStart)]);
}
