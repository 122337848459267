import { createSelector } from '@reduxjs/toolkit';

export const selectTableControlSlice = (state) => state.tables;

export const selectAllTablesData = createSelector([selectTableControlSlice], (tableControlSlice) => {
    return tableControlSlice?.allTablesData;
});

export const selectTableIdsFetchingDataInProgress = createSelector(
    [selectTableControlSlice],
    (tableControlSlice) => tableControlSlice.fetchingTableDataInProgress
);

export const selectTableConfig = createSelector([selectTableControlSlice], (tableControlSlice) => tableControlSlice.tableConfig.columns);

export const selectTableDataById = (id) =>
    createSelector([selectAllTablesData, (_, tableId = id) => tableId], function (allTablesData, tableId) {
        return allTablesData?.[tableId];
    });

export const tableConfigById = (id) =>
    createSelector([selectTableDataById(id), selectTableConfig], function (table, columnsConfig) {
        let tableData = [];
        if (table) {
            for (let page in table) {
                for (let transaction of table[page].data) {
                    tableData.push({
                        ...transaction,
                    });
                }
            }
        }

        return { tableData, columnsConfig, locationTransactions: table };
    });
