import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    locationValues: [],
    selectedRow: null,
    modalState: false,
    locations: [],
    isEditing: false,

    beforeEditData: null,
    formStatus: 'viewOnly',
    submittingFormError: null,

    monitoringObjectType: [
        { objectType: 'DOOR', id: 'DOOR' },
        { objectType: 'YARD', id: 'YARD' },
        { objectType: 'FENCE', id: 'FENCE' },
        { objectType: 'PARKING', id: 'PARKING' },
        { objectType: 'GATE IN', id: 'GATE_IN' },
        { objectType: 'GATE OUT', id: 'GATE_OUT' },
        { objectType: 'GATE IN-OUT', id: 'GATE_IN_OUT' },
    ],
};

const monitoringObjectList = createSlice({
    name: 'monitoringObjectList',
    initialState,
    reducers: {
        fetchTableValues() {},
        setTableValues(state, { payload: locationValues }) {
            state.locationValues = locationValues;
        },
        setSelectedRow(state, { payload: cameraId }) {
            state.selectedRow = [...state.locationValues].find((camera) => camera.id === cameraId);
        },
        setModalState(state, { payload: modalState }) {
            state.modalState = modalState;
        },

        resetState(state) {
            state.locationValues = [];
            state.cameraValues = [];
            state.selectedRow = null;
            state.modalState = false;
            state.locations = [];
            state.isEditing = false;
            state.beforeEditData = null;
            state.formStatus = 'viewOnly';
            state.submittingFormError = null;
        },
        setSelectedLocation(state, { payload: selectedLocation }) {
            state.selectedLocation = selectedLocation;
        },
        fetchLocation() {},
        setFetchedType(state, { payload }) {
            state.locations = payload;
        },
        setBeforeEditData(state, { payload: id }) {
            state.beforeEditData = [...state.locationValues].find((camera) => camera.id === id);
        },
        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },
        submitFormStart() {},
        submitFormFailed(state, { payload: error }) {
            state.submittingFormError = error;
        },
        setIsEditing(state, { payload }) {
            state.isEditing = payload;
        },
        fetchSingleMonitoringObject() {},
        setSingleMonitoringObject(state, { payload }) {
            state.selectedRow = payload;
        },
    },
});

export const monitoringObjectActions = monitoringObjectList.actions;
export const monitoringObjectReducer = monitoringObjectList.reducer;
