import styles from './App.module.css';
import React, { Suspense, useCallback, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import CircularLoader from 'components/UI/loaders/CircularLoader';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import NoPage from 'pages/NoPage';
import { sendLocalStorageSignal, sessionStorageTransferHandler } from 'utils/sessionStorageHandlers';
import { alertService } from 'services/AlertService';
import { getCookie } from 'utils/utils';
import { PROFILE } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserDataRequested, selectUser } from 'store/auth/authSelectors';
import { authActions } from 'store/auth/authSlice';
import ProtectedRoute from 'components/protectedRoute/ProtectedRoute';
import Redirect from 'pages/Redirect/Redirect';
import LinearLoader from 'components/UI/loaders/LinearLoader';
import NavigationDrawer from 'components/navigation/NavigationDrawer';
import CameraInfo from 'pages/CameraInfo/CameraInfo';
import ControlCentar from 'pages/ControlCentar/controlCentar';
import MonitoringObject from 'pages/MonitoringObjectPage/MonitoringObject';
import { StompSessionProvider } from 'react-stomp-hooks';
import { CONTROL_CENTAR_WEBSOKET } from 'config';

//pages (lazy imports)
const EmployeePage = React.lazy(() => import('pages/EmployeePage/EmployeePage'));
const PeopleAuthorizedListPage = React.lazy(() => import('pages/PeopleAuthorizedListPage/PeopleAuthorizedListPage'));
const MonitoringElements = React.lazy(() => import('pages/MonitoringElementsPage/MonitoringElements'));
const CompanyAuthorizedListPage = React.lazy(() => import('pages/CompanyAuthorizedListPage/CompanyAuthorizedListPage'));
const OrganizationListPage = React.lazy(() => import('pages/OrganizationListPage/OrganizationListPage'));
const DivisionListPage = React.lazy(() => import('pages/DivisionList/DivisionList'));
const LocationListPage = React.lazy(() => import('pages/LocationListPage/LocationListPage'));
const HomePage = React.lazy(() => import('pages/HomePage'));
const RegionsOfInterest = React.lazy(() => import('pages/RegionsOfInterest/RegionsOfInterest'));

function App() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const isUserDataRequested = useSelector(selectIsUserDataRequested);

    const removeCookie = () => {
        switch (PROFILE) {
            case 'development':
                document.cookie = 'lastaD=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            case 'staging':
                document.cookie = 'lastaS=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            case 'production':
                document.cookie = 'lastaP=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            default:
                document.cookie = 'lasta=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    };
    useEffect(() => {
        window.addEventListener('storage', sessionStorageTransferHandler);

        let cookieLasta;
        let cookieSso;
        let cookieAiq;
        let cookieAlert;
        let cookieGu;

        switch (PROFILE) {
            case 'development':
                cookieLasta = getCookie('lastaD');
                cookieSso = getCookie('ssoD');
                cookieAiq = getCookie('aiqD');
                cookieAlert = getCookie('alertD');
                cookieGu = getCookie('guD');
                break;
            case 'staging':
                cookieLasta = getCookie('lastaS');
                cookieSso = getCookie('ssoS');
                cookieAiq = getCookie('aiqS');
                cookieAlert = getCookie('alertS');
                cookieGu = getCookie('guS');
                break;
            case 'production':
                cookieLasta = getCookie('lastaP');
                cookieSso = getCookie('ssoP');
                cookieAiq = getCookie('aiqP');
                cookieAlert = getCookie('alertP');
                cookieGu = getCookie('guP');
                break;
            default:
                cookieLasta = getCookie('lasta');
                cookieSso = getCookie('sso');
                cookieAiq = getCookie('aiq');
                cookieAlert = getCookie('alert');
                cookieGu = getCookie('gu');
        }

        if (cookieSso || cookieAiq || cookieAlert || cookieGu || cookieLasta) {
            alertService.activate();
            return;
        } else {
            switch (PROFILE) {
                case 'development':
                    document.cookie = 'lastaD=AlertsON; path=/; ';
                    break;
                case 'staging':
                    document.cookie = 'lastaS=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                case 'production':
                    document.cookie = 'lastaP=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                default:
                    document.cookie = 'lasta=AlertsON; path=/; ';
            }
            alertService.activate();
        }

        window.addEventListener('beforeunload', removeCookie);
        return () => {
            window.removeEventListener('beforeunload', removeCookie);
            alertService.deactivate();
            window.removeEventListener('storage', sessionStorageTransferHandler);
        };
    }, []);

    useEffect(() => {
        if (!user) {
            !sessionStorage?.length && sendLocalStorageSignal('GetSessionStorage');
        }
    }, [dispatch, user]);

    useEffect(() => {
        !user && dispatch(authActions.getUserData());
    }, [user, dispatch]);

    const hasUserRole = useCallback((role) => user?.roles?.includes(role) || user?.roles?.includes('admin') || user?.roles?.includes('user'), [user]);

    if (!isUserDataRequested)
        return (
            <div className={styles['cheking-permission']}>
                <p>Checking permissions...</p>
                <LinearLoader containerStyles={{ position: 'relative' }} />
            </div>
        );
    return (
        <StompSessionProvider url={CONTROL_CENTAR_WEBSOKET}>
            {user && <NavigationDrawer user={user} />}
            <Suspense fallback={<CircularLoader />}>
                <Routes>
                    <Route path="/redirect" element={<Redirect user={user} />} />
                    <Route element={<ProtectedRoute isAllowed={!!user} redirectPath="/redirect" />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/regions-of-interest" element={<RegionsOfInterest user={user} />} />
                        <Route element={<ProtectedRoute isAllowed={hasUserRole('hr')} />}>
                            <Route path="/employees" element={<EmployeePage />} />
                        </Route>
                        <Route element={<ProtectedRoute isAllowed={hasUserRole('agent')} />}>
                            <Route path="/people-authorized-list" element={<PeopleAuthorizedListPage />} />
                            <Route path="/company-authorized-list" element={<CompanyAuthorizedListPage />} />
                        </Route>
                        <Route element={<ProtectedRoute isAllowed={hasUserRole('hq_support') || hasUserRole('sales')} />}>
                            <Route path="/organization-list" element={<OrganizationListPage />} />
                            <Route path="/location-list" element={<LocationListPage />} />
                            <Route path="/divison-list" element={<DivisionListPage />} />
                        </Route>
                        <Route element={<ProtectedRoute isAllowed={hasUserRole('tech')} />}>
                            <Route path="/monitoring-elements" element={<MonitoringElements />} />
                            <Route path="/control-center" element={<ControlCentar />} />
                            <Route path="/camera-tron" element={<CameraInfo />} />
                            <Route path="/monitoring-object" element={<MonitoringObject />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </Suspense>
        </StompSessionProvider>
    );
}

export default App;
