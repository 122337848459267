import { useEffect } from 'react';
import CameraElementsTable from 'features/cameraInfo/table/CameraElementsTable';
import { useDispatch, useSelector } from 'react-redux';
import LastRunInfo from 'features/cameraInfo/lastRunInfo/LastRunInfo';
import CameraElementPageActions from 'features/cameraInfo/actions/CameraElementPageActions';
import { cameraInfoActions } from 'store/cameraInfo/cameraInfoSlice';
import * as cameraInfoSelectors from 'store/cameraInfo/cameraInfoSelectors';

function CameraInfo() {
    const dispatch = useDispatch();
    const allCamerasInfo = useSelector(cameraInfoSelectors.selectCameraInfo);

    useEffect(() => {
        allCamerasInfo.length === 0 && dispatch(cameraInfoActions.fetchAllCameraInfo());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <CameraElementPageActions />
            <LastRunInfo></LastRunInfo>
            <CameraElementsTable />
        </>
    );
}

export default CameraInfo;
