import { createSelector } from '@reduxjs/toolkit';

export const monitoringObjectSelector = (state) => state.monitoringObjectList;

export const selectedTableValues = createSelector([monitoringObjectSelector], (slice) => slice.tableValues);
export const selectedSelectedRow = createSelector([monitoringObjectSelector], (slice) => slice.selectedRow);
export const modalState = createSelector([monitoringObjectSelector], (slice) => slice.modalState);
export const locationValues = createSelector([monitoringObjectSelector], (slice) => slice.locationValues);
export const locations = createSelector([monitoringObjectSelector], (slice) => slice.locations);
export const monitoringObjectType = createSelector([monitoringObjectSelector], (slice) => slice.monitoringObjectType);
export const selectBeforeEditData = createSelector([monitoringObjectSelector], (slice) => slice.beforeEditData);
export const selectFormStatus = createSelector([monitoringObjectSelector], (slice) => slice.formStatus);
export const selectIsEditing = createSelector([monitoringObjectSelector], (slice) => slice.isEditing);
