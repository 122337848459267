import { Button } from '@mui/material';
import Select from 'components/UI/inputs/inputSelect';
import { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as cameraInfoSelectors from 'store/cameraInfo/cameraInfoSelectors';
import { cameraInfoActions } from 'store/cameraInfo/cameraInfoSlice';
import styles from './CameraElementPageActions.module.css';

function CameraElementPageActions() {
    const dispatch = useDispatch();
    const selectedCamera = useSelector(cameraInfoSelectors.selectSelectedCamera);
    const allLocationsLookup = useSelector(cameraInfoSelectors.selectAllLocationsLookup);
    const selectedLocation = useSelector(cameraInfoSelectors.selectSelectedLocation);
    const refreshLocationInProgress = useSelector(cameraInfoSelectors.selectRefreshLocationInProgress);
    const refreshSingleCameraInProgress = useSelector(cameraInfoSelectors.selectRefreshSingleCameraInProgress);

    const isOptionEqualToValueFn = (option, value) => option.id === value.id;

    const refreshSingleCamera = () => {
        dispatch(cameraInfoActions.refreshCameraInfo(selectedCamera));
    };

    const changeHandlerLocation = (_, newValue) => {
        if (newValue) {
            dispatch(cameraInfoActions.setSelectedLocation(newValue));
            dispatch(cameraInfoActions.refreshCameraInfoPerLocation(newValue));
        }
    };

    useLayoutEffect(() => {
        dispatch(cameraInfoActions.fetchAllLocations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles['actions-container']}>
            <div>
                <p className={styles['actions-heading']}>Refresh Single Camera: </p>
                <div className={styles['actions']}>
                    <Button
                        disabled={!selectedCamera || refreshSingleCameraInProgress}
                        variant="outlined"
                        size="small"
                        onClick={refreshSingleCamera}
                        className={styles['actions__button']}
                    >
                        <RefreshIcon />
                    </Button>
                    {refreshSingleCameraInProgress && <RefreshIcon className={styles['loader']} />}
                </div>
            </div>
            <div>
                <p className={styles['actions-heading']}>Refresh Cameras per location: </p>
                <div className={styles['actions-location']}>
                    <div className={styles['select-container']}>
                        <Select
                            disabled={refreshLocationInProgress}
                            label="Location"
                            isOptionEqualToValueFn={isOptionEqualToValueFn}
                            changeHandler={changeHandlerLocation}
                            value={selectedLocation}
                            lookup={allLocationsLookup}
                        />
                    </div>

                    {refreshLocationInProgress && <RefreshIcon className={styles['loader']} />}
                </div>
            </div>
        </div>
    );
}

export default CameraElementPageActions;
