export const monitoringObject = {
    locationListForm: {
        id: 'monitoringObjectForm',
        fields: [
            // {
            //     fieldName: 'location',
            //     label: 'Location',
            //     type: 'text',
            //     initialValue: null,
            //     required: true,
            //     disabled: false,
            //     lookup: { displayExpr: 'yardName' },
            // },
            {
                fieldName: 'name',
                label: 'Name',
                type: 'text',
                initialValue: null,
                required: true,
            },
            {
                fieldName: 'objectType',
                label: 'Type',
                type: 'text',
                initialValue: null,
                required: true,
                disabled: false,
                lookup: { displayExpr: 'objectType', valueExpr: 'id' },
            },
            {
                fieldName: 'abbreviation',
                label: 'Abbreviation',
                type: 'text',
                initialValue: null,
                required: false,
            },

            {
                fieldName: 'description',
                label: 'Description',
                type: 'text',
                initialValue: null,
                required: false,
            },
        ],
    },
};
