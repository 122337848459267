import store from 'store/store';
import { authActions } from '../store/auth/authSlice';

export const sessionStorageTransferHandler = function (e) {
    if (!e.newValue) return;

    if (e.key === 'GetSessionStorage') {
        sendLocalStorageSignal('SessionStorage', sessionStorage);
    }

    if (e.key === 'SessionStorage' && !sessionStorage.length) {
        const session = JSON.parse(e.newValue);
        for (let key in session) {
            sessionStorage.setItem(key, JSON.stringify(JSON.parse(session[key])));
            if (key === 'user') store.dispatch(authActions.setUser(JSON.parse(session[key])));
        }
    }

    if (e.key === 'UserLoggedOut') {
        sessionStorage.removeItem('user');
        store.dispatch(authActions.logoutStart());
    }
};

export function sendLocalStorageSignal(key, value = '_') {
    localStorage.setItem(key, JSON.stringify(value));
    setTimeout(() => localStorage.removeItem(key), 100);
}
