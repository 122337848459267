import { axiosLasta } from 'config';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import notificationsHandler from 'utils/notificationsHandler';
import { FormDivisionSaga } from 'store/formControl/formDivisionSaga';
import { divisionListActions } from './divisionListSlice';

export function* fetchMasterList() {
    try {
        const response = yield axiosLasta(`/station/division-with-desks/all?includeInactive=true`);
        const { data } = response;
        yield put(divisionListActions.fetchMasterListSuccess(data));
    } catch (err) {
        yield notificationsHandler({ title: 'Error fetching all divisions' });
    }
}

export function* onAddDeskToDivision() {
    yield takeLatest(divisionListActions.addItemToAuthorizedList.type, FormDivisionSaga);
}

export function* onFetchingMasterListStart() {
    yield takeEvery(divisionListActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(divisionListActions.submitFormStart, FormDivisionSaga);
}

export function* onAddItemToMasterList() {
    yield takeLatest(divisionListActions.addItemToMasterList.type, FormDivisionSaga);
}

export function* divisionListSaga() {
    yield all([call(onSubmitFormStart), call(onAddItemToMasterList), call(onFetchingMasterListStart), call(onAddDeskToDivision)]);
}
