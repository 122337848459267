import * as cameraInfoSelectors from 'store/cameraInfo/cameraInfoSelectors';
import { useSelector } from 'react-redux';
import styles from './LastRunInfo.module.css';

function LastRunInfo() {
    const lastRunStatus = useSelector(cameraInfoSelectors.selectLastRunStatus);
    const lastRunTime = useSelector(cameraInfoSelectors.selectLastRunTime);

    return (
        <div className={styles['last-run-info']}>
            {/* <p>Last run: {lastRunTime}</p> */}
            <p>Last run status: {lastRunStatus}</p>
        </div>
    );
}

export default LastRunInfo;
