export const routes = [
    {
        path: '/',
        text: 'Homepage',
        Icon: 'Home',
    },
    {
        allowedRoles: ['admin', 'user', 'hr'],
        path: '/employees',
        text: 'Employees',
    },
    {
        allowedRoles: ['admin', 'user', 'agent'],
        path: '/people-authorized-list',
        text: 'Location’s Authorized People',
    },
    {
        allowedRoles: ['admin', 'user', 'agent'],
        path: '/company-authorized-list',
        text: 'Location’s Authorized Companies',
    },
    {
        allowedRoles: ['admin', 'user', 'tech'],
        path: '/monitoring-elements',
        text: 'Monitoring Elements',
    },
    {
        allowedRoles: ['admin', 'user', 'hq_support', 'sales'],
        path: '/organization-list',
        text: 'Organizations',
    },
    {
        allowedRoles: ['admin', 'user', 'hq_support', 'sales'],
        path: '/location-list',
        text: 'Locations',
    },
    {
        allowedRoles: ['admin', 'user', 'hq_support', 'sales'],
        path: '/divison-list',
        text: 'Divisions',
    },
    {
        allowedRoles: ['admin', 'user', 'tech'],
        path: '/camera-tron',
        text: 'CameraTron',
    },
    {
        allowedRoles: ['admin', 'user', 'tech'],
        path: '/control-center',
        text: 'Control Center',
    },
    {
        allowedRoles: ['admin', 'user', 'tech'],
        path: '/monitoring-object',
        text: 'Monitoring Object',
    },

    { text: 'Alisa', items: [{ path: '/regions-of-interest', text: 'Regions Of Interest' }] },
];
