import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/UI/inputs/inputSelect';
import { Button } from '@mui/material';
import { monitoringObjectActions } from 'store/monitoringObject/monitoringObjectSlice';
import { companyAuthorizationListActions } from 'store/companyAuthorizedList/companyAuthorizationListSlice';
import { selectAllLocationsLookup, selectSelectedLocation } from 'store/companyAuthorizedList/companyAuthorizationListSelectors';
import styles from './Header.module.css';

function Header({ addHandler }) {
    const allLocationsLookup = useSelector(selectAllLocationsLookup);
    const selectedLocation = useSelector(selectSelectedLocation);
    const dispatch = useDispatch();

    const changeHandler = (_, newValue) => {
        if (!newValue) {
            dispatch(monitoringObjectActions.resetState());
        } else {
            dispatch(companyAuthorizationListActions.setSelectedLocation(newValue));
            dispatch(monitoringObjectActions.fetchTableValues(newValue));
        }
    };

    return (
        <div className={styles['header-container']}>
            <div className={styles['header']}>
                <Select label="Location" changeHandler={changeHandler} value={selectedLocation} lookup={allLocationsLookup} />
            </div>
            <Button
                className={styles['header-addButton']}
                variant="contained"
                color="success"
                size="small"
                onClick={addHandler}
                disabled={!selectedLocation}
            >
                add
            </Button>
        </div>
    );
}

export default Header;
