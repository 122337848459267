import Alerts from 'components/UI/Alerts/Alerts';
import { useEffect } from 'react';
import { useRef } from 'react';
import styles from './Option.module.css';

// const alerts = [
//     { text: 1, color: '#16A085' },
//     { color: '#F1C40F' },
//     { color: '#C0392B' },
// ];

function Option({
    option,
    onChange,
    setDropdownOpened,
    setInputValue,
    activeOption,
    setHoveredOption,
    hoveredOption,
    selectedOption,
    setSelectedOptionIndex,
    optionIndex,
    getInputValue,
}) {
    const optionLabel = getInputValue(option);
    const optionRef = useRef();

    useEffect(() => {
        if (activeOption) setSelectedOptionIndex(optionIndex);
    }, [activeOption, optionIndex, setSelectedOptionIndex]);

    useEffect(() => {
        if (selectedOption)
            optionRef.current.scrollIntoView({
                block: 'nearest',
                inline: 'start',
            });
    }, [selectedOption]);

    const optionStyles = `${styles['option']}${activeOption ? ` ${styles['option--active']}` : ''}${
        hoveredOption ? ` ${styles['option--highlighted']}` : selectedOption ? ` ${styles['option--selected']}` : ''
    }`;

    const mouseDownHandler = (e) => {
        setInputValue(getInputValue(option));
        onChange(e, option);
        setDropdownOpened(false);
        const autocompleteContainer = optionRef.current.closest('div[class*="AIQAutocomplete"]');
        const currentInputId = autocompleteContainer.querySelector('input').id;
        const inputToFocus = document.getElementById(`${+currentInputId + 1}`);
        inputToFocus && setTimeout(() => inputToFocus.focus(), 0);
    };

    const mouseOverHandler = (e) => {
        setHoveredOption(option);
        setSelectedOptionIndex(optionIndex);
    };

    return (
        <div
            className={`${styles['option-container']} ${optionStyles}`}
            onMouseDown={mouseDownHandler}
            onMouseOver={mouseOverHandler}
        >
            <Alerts alerts={[{ color: option.color }]} />
            <p className={styles['option-text']} ref={optionRef}>
                {optionLabel}
            </p>
        </div>
    );
}

export default Option;
