export const monitoringObjectConfig = [
    {
        columns: [
            {
                caption: 'Id',
                dataField: 'id',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Location Name',
                dataField: 'location.yardName',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Name',
                dataField: 'name',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Abbreviation',
                dataField: 'abbreviation',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Type',
                dataField: 'objectType',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Description',
                dataField: 'description',
                dataType: 'string',
                editable: false,
            },
        ],
    },
];
